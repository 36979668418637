<template>
  <!--服务项目配置-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="名称/编码"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="reload">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" @click="openEdit(null)">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新增</span>
                </el-button>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:classify= "{ scope }">
                <div v-for="(item) in dict.type.projectclassification">
                  <span v-if="scope.row.classify == item.value">{{item.label}}</span>
                </div>
              </template>
              <!--自定义列显示 示例-->
              <template v-slot:is_attestation= "{ scope }">
                <div v-for="(item) in dict.type.Techniciancertification">
                  <span v-if="scope.row.is_attestation == parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" type="info">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--角色编辑-->
      <Edit :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>
    </div>


  </div>
</template>

<script>
//引入项目详情
import Edit from './components/edit.vue'

// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  Delservices_available,
  getservices_available,
} from '@/api/system'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    Edit,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "项目分类",
          // type: "index",
          // width: "70"
          prop: "classify",
          slot: 'classify',
          isShow: true
        },
        {
          label: "项目名称",
          prop: "name",
          isShow: true
        },
        {
          label: "是否司机认证",
          prop: "is_attestation",
          slot: 'is_attestation',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //编辑
      current:null,
      showEdit:false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '财务字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getList();
  },

  methods:{
    /* 刷新表格 */
    reload() {
      this.getList();
    },
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      };
      this.reload();
    },

    // 获取列表
    getList(){
      getservices_available(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },

    // 删除
    del(row){
      Delservices_available(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //编辑
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
