<!-- 项目详情 -->
<template>
  <div>
    <el-dialog
      width="50%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'项目详情':'项目详情'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px">
        <el-form-item label="项目编号:" prop="serial_number">
          <el-input
            clearable
            v-model="form.serial_number"
            placeholder="请输入项目编号"/>
        </el-form-item>
        <el-form-item label="项目名称:" prop="name">
          <el-input
            clearable
            v-model="form.name"
            placeholder="请输入项目名称"/>
        </el-form-item>
        <el-form-item label="项目分类:" prop="classify">
          <el-select
            clearable
            v-model="form.classify"
            placeholder="请选择"
            class="ele-fluid">
            <el-option
              v-for="(item) in dict.type.projectclassification"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否司机认证:" prop="is_attestation">
          <el-select
            clearable
            v-model="form.is_attestation"
            placeholder="请选择"
            class="ele-fluid">
            <el-option
              v-for="(item) in dict.type.Techniciancertification"
              :label="item.label"
              :value="parseInt(item.value)"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            clearable
            v-model="form.sort"
            placeholder="请输入认证说明"/>
        </el-form-item>
        <el-form-item label="认证说明:" prop="explain">
          <el-input
            clearable
            v-model="form.explain"
            placeholder="请输入认证说明"/>
        </el-form-item>
        <el-form-item label="认证示例图:">
          <el-upload
            :action="action"
            :headers="headers"
            :on-success="successUpload"
            :file-list="fileList"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="icon:">
          <el-upload
            :class="{hide_box: upload_btn}"
            :limit="1"
            :action="action"
            :headers="headers"
            :on-change="change"
            :on-success="successUpload1"
            :file-list="fileList1"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview1"
            :on-remove="handleRemove1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

      </el-form>


      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
        </el-button>
      </div>

    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible1" :before-close="handleClose1">
      <img width="100%" :src="dialogImageUrl1" alt="">
    </el-dialog>
  </div>

</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

//引入的接口
import {Addservices_available, Editservices_available} from "@/api/system";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({sample_graph:[]}, this.data),
      // 验证规则
      rules: {
        serial_number:[
          { required: true, message: '请输入项目编号', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        classify:[
          { required: true, message: '请选择项目分类', trigger: 'change' },
        ],
        is_attestation: [
          { required: true, message: '请选择是否认证', trigger: 'change' },
        ],
        sort:[
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
        explain:[
          { required: true, message: '请输入认证说明', trigger: 'blur' },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/system',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[],

      dialogImageUrl1: '',
      dialogVisible1: false,
      fileList1:[],
      upload_btn: false, // 是否隐藏上传图片按钮

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data);
        this.form = Object.assign({}, this.data);
        this.fileList = [];
        this.form.sample_graph.forEach(item => {
          let data = {
            url: item
          }
          this.fileList.push(data);
        })


        let data1 = {
          url: this.form.icon
        }
        this.upload_btn = true;
        this.fileList1.push(data1);

        this.isUpdate = true;
      } else {
        this.form = {sample_graph:[]};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.updateVisible(false);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.sample_graph.push(file.data.urls);
    },
    handleClose(){
      this.dialogVisible = false;
      this.updateVisible(true);
    },

    handleRemove1(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
      this.updateVisible(false);
    },
    change(){
      this.upload_btn = true;
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl1 = file.data.urls;
      // 上传成功后的列表
      this.form.icon = file.data.urls;
    },

    handleClose1(){
      if(this.form.icon == ''){
        this.upload_btn = true;
      }
      this.dialogVisible1 = false;
      this.updateVisible(true);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        console.log(this.form);
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addservices_available(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editservices_available(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
